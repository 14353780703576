<template>
    <nav class="navbar style-basic sticky-top navbar-md navbar-expand-lg py-0">
        <div class="container d-flex justify-content-center">
            <router-link class="navbar-brand" to="/">
                <img src="@/assets/img/logo.jpeg" width="38" height="32" alt="Логотип Domus Decor">
                <span class="d-none d-sm-inline d-md-none d-xl-inline ml-2">DomusDecor</span>
            </router-link>

            <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <i class="fa fa-bars navbar-toggler-icon d-flex" aria-hidden="true"></i>
            </button>

            <div class="collapse navbar-collapse mb-2 mb-md-0" id="navbarSupportedContent">
                <ul class="navbar-nav row justify-content-md-left ml-auto">
                    <li class="nav-item col-12 col-md-auto d-flex p-0">
                        <router-link class="nav-link text-center font-global m-auto mx-md-0" to="/">Главная</router-link>
                    </li>
                    <li class="nav-item col-12 col-md-auto d-flex p-0">
                        <router-link class="nav-link text-center font-global m-auto mx-md-0" to="/catalog">Каталог</router-link>
                    </li>
                    <li class="nav-item col-12 col-md-auto d-flex p-0">
                        <router-link class="nav-link text-center font-global m-auto mx-md-0" to="/calculator">Калькулятор</router-link>
                    </li>
                    <li class="nav-item col-12 col-md-auto d-flex p-0">
                        <router-link class="nav-link text-center font-global m-auto mx-md-0" to="/contacts">Контакты</router-link>
                    </li>
                    <!--li class="nav-item col-12 col-md-auto d-flex p-0">
                        <router-link class="nav-link text-center font-global m-auto mx-md-0" to="/gallery">Галерея</router-link>
                    </li-->
                    <li class="nav-item col-12 col-md-auto d-flex p-0">
                        <router-link class="nav-link text-center font-global m-auto mx-md-0" to="/reviews">Отзывы</router-link>
                    </li>
                    <!--li class="nav-item col-12 col-md-auto d-flex p-0">
                        <router-link class="nav-link text-center font-global m-auto mx-md-0" to="/learn">Обучение</router-link>
                    </li-->
                </ul>

                <!--form class="form-inline ml-auto d-flex">
                    <router-link class="btn style-outline-active default$ px-4 ml-auto mr-auto mr-md-0 my-3 my-md-0" to="/order?title=develop">Заказать</router-link>
                </form-->
            </div>
        </div>
    </nav>
</template>

<script>
export default {
  name: 'Navbar',
}
</script>