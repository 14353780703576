//Vue.js
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

//Lazyload
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
    preLoad: 1.3,
    //loading: require("./assets/img/loading.gif"),
    attempt: 1,
});

//Bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

//Data
import products from "./assets/data/products.json";
import options from "./assets/data/productoptions.json";
import reviews from "./assets/data/reviews.json";
window.data = {
    products: products,
    options: options,
    reviews: reviews,

    calculatePrice: function(productId, meters) {
        var product = window.data.products.filter((product) => product.id == productId)[0];

        //prepare
        var prices = {};
        for (var val in product.priceBuy) {
            //1kg: 1000 => 1: 1000
            prices[Number(val.replace("kg", ""))] = product.priceBuy[val];
        }
        var quantityArr = Object.keys(prices).sort(function(a, b) {
            return b - a;
        });

        //calculate
        var usedQuantities = {};
        var kgConsumption = meters * product.consumption;
        var _kgConsum = kgConsumption;
        var totalPrice = 0;
        for (var i in quantityArr) {
            var quantity = quantityArr[i];

            var quanNum;
            if (kgConsumption > quantity) {
                let kgConsum = kgConsumption
                if(!quantityArr.includes((kgConsum - Math.floor(kgConsum / quantity)).toString()))
                    kgConsum = Math.ceil(kgConsum)
                quanNum = Math.floor(kgConsum / quantity);

                //add price
                totalPrice += prices[quantity] * quanNum;

                //subtract from required consumption
                kgConsumption -= quantity * quanNum;

                //add info about use of this quantity
                usedQuantities[quantity] = {
                    basePrice: prices[quantity],
                    price: prices[quantity] * quanNum,
                    amount: quanNum
                };
            }
            //is last quantity
            else if (i == quantityArr.length - 1 && kgConsumption > 0) {
                //add price
                totalPrice += prices[quantity];

                //done
                kgConsumption = 0;

                //add info about use of this quantity
                usedQuantities[quantity] = {
                    basePrice: prices[quantity],
                    price: prices[quantity],
                    amount: 1
                };
            }
        }

        return {
            name: product.nameru,
            kgConsumption: Math.round((_kgConsum + Number.EPSILON) * 100) / 100,

            price: totalPrice,
            used: usedQuantities
        }
    },

    calculateOption: function(optionName, meters) {
        var option = window.data.options.filter((option) => option.name == optionName)[0];

        //prepare
        var prices = {};
        for (var val in option.priceBuy) {
            //1kg: 1000 => 1: 1000
            prices[Number(val.replace("kg", ""))] = option.priceBuy[val];
        }
        var quantityArr = Object.keys(prices).sort(function(a, b) {
            return b - a;
        });

        //calculate
        var usedQuantities = {};
        var kgConsumption = meters * option.consumption;
        var _kgConsum = kgConsumption;
        var totalPrice = 0;
        for (var i in quantityArr) {
            var quantity = quantityArr[i];

            var quanNum;
            if (kgConsumption > quantity) {
                let kgConsum = kgConsumption
                if(!quantityArr.includes((kgConsum - Math.floor(kgConsum / quantity)).toString()))
                    kgConsum = Math.round(kgConsum)
                quanNum = Math.floor(kgConsum / quantity);

                //add price
                totalPrice += prices[quantity] * quanNum;

                //subtract from required consumption
                kgConsumption -= quantity * quanNum;

                //add info about use of this quantity
                usedQuantities[quantity] = {
                    basePrice: prices[quantity],
                    price: prices[quantity] * quanNum,
                    amount: quanNum
                };
            }
            //is last quantity
            else if (i == quantityArr.length - 1 && kgConsumption > 0) {
                //add price
                totalPrice += prices[quantity];

                //done
                kgConsumption = 0;

                //add info about use of this quantity
                usedQuantities[quantity] = {
                    basePrice: prices[quantity],
                    price: prices[quantity],
                    amount: 1
                };
            }
        }

        return {
            name: option.nameru,
            kgConsumption: Math.round((_kgConsum + Number.EPSILON) * 100) / 100,

            price: totalPrice,
            used: usedQuantities
        }
    } 
};

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
