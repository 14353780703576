import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
    },
    /* Catalog */
    {
        path: "/catalog",
        name: "Catalog",
        component: () => import("../views/Catalog.vue"),
    },
    {
        path: "/catalog/view",
        name: "Product",
        component: () => import("../views/Product.vue"),
    },
    /* Other */
    {
        path: "/calculator",
        name: "Calculator",
        component: () => import("../views/Calculator.vue"),
    },
    {
        path: "/order",
        name: "Order",
        component: () => import("../views/Order.vue"),
    },
    {
        path: "/buy",
        name: "Buy",
        component: () => import("../views/Buy.vue"),
    },
    {
        path: "/contacts",
        name: "Contacts",
        component: () => import("../views/Contacts.vue"),
    },
    {
        path: "/gallery",
        name: "Gallery",
        component: () => import("../views/Gallery.vue"),
    },
    {
        path: "/reviews",
        name: "Reviews",
        component: () => import("../views/Reviews.vue"),
    },
    {
        path: "/learn",
        name: "Learn",
        component: () => import("../views/Learn.vue"),
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        const navbarToggle = document.querySelector('[data-toggle="collapse"]')
        const navbarPanel = document.querySelector('.navbar-collapse')
        console.log(navbarToggle, navbarPanel.classList.contains('show'))
        // Hide navbar
        if(navbarToggle && navbarPanel.classList.contains('show') && window.document.documentElement.clientWidth <= 768) {
            navbarToggle.click()
        }
        return { x: 0, y: 0 }
    }
});

export default router;
