<template>
    <div id="app">
        <Navbar/>
        <router-view/>
        <Footer/>
    </div>
</template>

<script>
import Navbar from './components/core/Navbar.vue'
import Footer from './components/core/Footer.vue'

export default {
    components: {
        Navbar,
        Footer
    }
}
</script>

<style>
@import './assets/vendor/fontawesome-free/css/all.min.css';
@import './assets/vendor/fontawesome-free/css/brands.min.css';
@import './assets/vendor/fontawesome-free/css/v4-shims.min.css';
@import './assets/css/themeky.css';
@import './assets/css/main.css';
</style>