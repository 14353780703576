<template>
    <footer>
        <div class="section-content style-dark">
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4 pb-4">
                        <p class="color-dark-header h3">Domus Decor</p>
                        <p>
                            <small class="block color-dark-text">
                                © 2008-{{ new Date().getFullYear() }} ООО "ДОМУС". Все права защищены. Публикация материалов сайта без официального разрешения запрещена.
                                Сайт носит информационный характер и не является публичной офертой.
                            </small>
                        </p>
                        <!-- Media-->
                        <a class="btn btn-icon style-secondary-dark border-sm default$ border-active$" href="https://instagram.com/decor_domus" target="_blank">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </div>
                    <div class="col-md-8 text-md-right pb-5">
                        <a href="mailto:domus731@yandex.ru" class="color-dark-header h4 link">domus731@yandex.ru</a>
                        <br>
                        <a href="tel:79063920511" class="color-dark-header h4 link">+7 (906) 392-05-11</a>
                    </div>
                    <div class="col-12 text-center">
                        <p class="color-dark-text">
                            Разработка и продвижение сайта - <a target="_blank" rel="noopener" href="https://proxymal.ru?utm=domus-decor" class="color-dark-header link">Proxymal</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>